.productsContainer {
  width: 100%;
  background-color: var(--white);
  color: var(--black);
}
.productsContainer > h1 {
  font-size: 40px;
  text-align: center;
  padding-bottom: 2rem;
  letter-spacing: 2px;
}
.filter {
  background-color: var(--red);
  border-radius: 1rem;
  padding: 1rem 2rem;
  position: sticky;
  top: 5.03rem;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.filter input {
  width: 500px;
  font-size: 20px;
}
.filter h1 {
  color: var(--white);
  font-size: 25px;
}
.button {
  padding: 3px 2rem;
  font-size: 25px;
  border: none;
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  margin-left: 1rem;
}
.products {
  width: 100%;
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, minmax(15rem, 1fr));
  gap: 2rem 1rem;
}
.producting h1 {
  padding: 3rem 0;
  text-align: center;
}
.NoProduct {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NoProduct img {
  width: 400px;
}
.NoProduct h1 {
  font-size: 25px;
}

@media screen and (max-width: 1300px) {
  .products {
    grid-template-columns: repeat(4, minmax(15rem, 1fr));
  }
}

@media screen and (max-width: 1080px) {
  .products {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
  }
  .filter input {
    width: 250px;
    font-size: 17px;
  }
  .button {
    padding: 8px 1rem;
    font-size: 20px;
  }
}

@media screen and (max-width: 750px) {
  .products {
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
  }
  .filter {
    flex-direction: column;
    gap: 1rem;
  }
  .filter input {
    width: 450px;
    font-size: 17px;
  }
  .button {
    padding: 8px 1rem;
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .products {
    grid-template-columns: repeat(1, minmax(15rem, 1fr));
  }
  .filter input {
    width: 250px;
    font-size: 17px;
  }
  .button {
    padding: 8px 1rem;
    font-size: 20px;
  }
}
