@import url("https://fonts.googleapis.com/css2?family=Combo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Combo", sans-serif;
  scroll-behavior: smooth !important;
}

:root {
  --red: #ff1e00;
  --blue: #3120e0;
  --white: #fff;
  --black: #000;
  --shadow: inset 0px -13px 106px -56px rgba(219, 28, 28, 1);
}

.darkTheme {
  --red: #be2814;
  --blue: #533483;
  --white: #000;
  --black: #fff;
  --shadow: inset 0px -13px 106px -56px rgba(219, 28, 28, 1);
}

.app {
  background-color: var(--white);
  color: var(--black);
}
