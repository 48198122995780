.footerContainer {
    width: 100%;
    padding: 3rem;
    background-color: gray;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 1rem;
}

.contact h1 {
    font-size: 40px;
}

.contact h5,
h6 {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 1rem;
}

@media screen and (max-width:700px) {
    .footerContainer{
        flex-direction: column;
        gap: 3rem;
    }
}