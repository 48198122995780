.navContainer {
  width: 100%;
  position: sticky;
  z-index: 100;
  top: 0%;
  max-height: 5rem;
  background-color: var(--white);
  box-shadow: var(--shadow);
  color: var(--black) !important;
}

.header {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header a {
  text-decoration: none;
}
.logo {
  font-family: "Ms Madi" !important;
  color: var(--black);
  font-size: 45px !important;
  font-weight: 600 !important;
}

.header .navbar {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar h1 {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  margin: 0 3rem;
  color: var(--black);
}

.navbar h1:hover {
  color: var(--red);
}

.options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--black) !important;
}

.theme {
  display: flex;
  align-items: center;
  font-size: 30px !important;
}

.options h1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 300;
  color: var(--black);
  margin: 0 1rem;
}

.options h1:hover {
  color: var(--red);
}

.navbarMob {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  transition: 1s ease;
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.overlay h1 {
  cursor: pointer;
  color: var(--black);
  margin: 1rem 2rem;
  font-size: 25px !important;
}

.overlay h1:hover {
  text-decoration: underline !important;
  color: var(--black);
}

.navbarMob .close {
  font-size: 35px !important;
  color: var(--black);
  position: absolute;
  top: 13px;
  right: 10px;
  cursor: pointer;
}

.navbarMob .menu {
  color: var(--black);
  font-size: 30px !important;
  cursor: pointer;
}
.cart {
  color: var(--black);
}
.themeSwitcher {
  color: var(--black) !important;
}
.optionsM {
  display: flex;
  align-items: center;
  font-size: 30px !important;
}

@media screen and (max-width: 2000px) {
  .logo img {
    width: 220px;
    filter: invert(var(--invert));
  }
}

@media screen and (max-width: 1150px) {
  .header .navbar {
    display: none;
  }

  .header .navbarMob {
    display: flex;
  }

  .header .navbarMob .optionsM {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .options {
    display: none;
  }

  .navbar {
    padding: 1rem;
  }

  .logo img {
    width: 140px;
  }

  hr {
    width: 50%;
  }

  .header .navbarMob .optionsM {
    display: flex;
  }

  .overlay {
    padding-top: 6%;
    justify-content: flex-start;
  }

  .overlay h1 {
    cursor: pointer;
    color: var(--black);
    margin: 1rem 2rem;
    font-size: 3.5vh;
  }
}
