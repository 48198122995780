.sign {
  width: 100%;
  background-color: var(--white);
}

.signContainer {
  width: 40%;
  margin: auto;
  color: var(--black);
  padding: 3rem 0;
}
.title {
  text-align: center;
  font-size: 2.5rem;
  color: var(--black);
}
.formContainer {
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 2vh;
  height: 70vh;
  box-shadow: var(--shadow);
}

.formContainer div {
  width: 70%;
  height: 100%;
  margin: 2vh auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem 0;
}
.formContainer h1 {
  font-size: 25px;
  font-weight: 300;
  margin: 0.5rem 0;
}

.formContainer input {
  font-size: 18px;
  font-weight: 300;
  padding: 0.2rem 0.5rem;
  border-radius: 1vh;
  outline-color: var(--black);
  caret-color: var(--red);
}

.formContainer button {
  font-size: 20px;
  padding: 0.3rem 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 1vh;
  border: none;
  background-color: var(--red);
  color: var(--black);
}

@media screen and (max-width: 900px) {
  .signContainer {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .signContainer {
    width: 90%;
  }
  .formContainer div {
    width: 90%;
    height: 100%;
    margin: 2vh auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 0;
  }
}
