.checkoutContainer {
  width: 100%;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  color: var(--black);
}
.checkoutContainer h1 {
  padding: 2rem 0;
}
